import type { HubTouchpointConfig } from '~/types/configuration'

export const useTouchpointStore = defineStore('touchpoint', () => {
  const organisationStore = useOrganisationStore()
  const { currentOrganisationId } = storeToRefs(organisationStore)

  const touchpointList: Ref<Array<HubTouchpointConfig>> = ref([])
  const touchpointByOrganisationList: Ref<Array<{
    rootOrgId: number
    touchpoints: Array<HubTouchpointConfig>
  }>> = ref([])

  const computedTouchpointList: ComputedRef<Array<HubTouchpointConfig>> = computed(() => touchpointList.value)
  const currentOrganisationTouchpoints: ComputedRef<Array<HubTouchpointConfig>> = computed(() => {
    return touchpointByOrganisationList.value
      .find(item => item.rootOrgId === currentOrganisationId.value)?.touchpoints
      || []
  })

  async function fetchTouchpoints() {
    if (touchpointList.value && touchpointList.value.length) {
      return touchpointList.value
    }

    const { error } = await useHubFetch<Array<HubTouchpointConfig>>('api/v4/lookups/dashboards/touchpoints', {
      onResponse: response => {
        touchpointList.value = response.response._data.sort(
          (a: HubTouchpointConfig, b: HubTouchpointConfig) => a.ordinal - b.ordinal
        )
      }
    })

    if (error.value) {
      throw new Error('Touchpoints Not Loaded')
    }

    return touchpointList.value
  }

  async function fetchTouchpointForOrganisation(rootOrgId: number, secondAttempt: boolean = false) {
    if (touchpointByOrganisationList.value.length) {
      const orgTouchpoints = touchpointByOrganisationList.value.find(item => item.rootOrgId === rootOrgId)
      if (orgTouchpoints) {
        return orgTouchpoints.touchpoints
      }
    }

    if (secondAttempt) return []

    await useHubFetch<Array<HubTouchpointConfig>>('api/v4/lookups/dashboards/touchpoints', {
      query: { rootOrganisationNodeId: rootOrgId },
      onResponse: ({ response }) => {
        touchpointByOrganisationList.value.push({
          rootOrgId: rootOrgId,
          touchpoints: response._data.sort(
            (a: HubTouchpointConfig, b: HubTouchpointConfig) => a.ordinal - b.ordinal
          )
        })
      }
    })

    return fetchTouchpointForOrganisation(rootOrgId, true)
  }

  return {
    // internal refs
    touchpointList,
    touchpointByOrganisationList,

    // public
    computedTouchpointList,
    currentOrganisationTouchpoints,

    fetchTouchpoints,
    fetchTouchpointForOrganisation
  }
})
